import _ from 'lodash'
import { Connections, ControllerDataItem, ControllerDataAPI, AppParams, WixCodeApi, PlatformAPI, PlatformServicesAPI, WidgetNames } from '@wix/thunderbolt-symbols'
import { WixSelector } from '../wixSelector'
import { CreateSetPropsForOOI } from '../setPropsFactory'

export function createControllersParams(
	createSetPropsForOOI: CreateSetPropsForOOI,
	controllersData: Array<ControllerDataItem>,
	connections: Connections,
	wixSelector: WixSelector,
	widgetNames: WidgetNames,
	appParams: AppParams,
	wixCodeApi: WixCodeApi,
	platformAppServicesApi: PlatformServicesAPI,
	platformApi: PlatformAPI,
	csrfToken: string
): Array<{ controllerCompId: string; controllerParams: ControllerDataAPI }> {
	return controllersData.map((controllerData) => {
		const { controllerType, compId, templateId, config, externalId } = controllerData
		return {
			controllerCompId: compId,
			controllerParams: {
				$w: wixSelector.create$w(compId),
				compId: templateId || compId,
				name: widgetNames[controllerType] || controllerType,
				type: controllerType,
				config,
				connections: _.flatMap(connections[compId], _.values),
				warmupData: null,
				appParams,
				platformAPIs: Object.assign(platformApi, platformAppServicesApi),
				wixCodeApi,
				csrfToken,
				setProps: createSetPropsForOOI(compId),
				externalId,
			},
		}
	})
}
