import _ from 'lodash'
import { ControllerDataItem, AppModule, PlatformAPI, PlatformUtils, PlatformLogger, ControllersApi } from '@wix/thunderbolt-symbols'
import { BootstrapData } from '../types'
import type { ControllersExports, ModelsAPI } from './types'
import { createAppParams } from './appsAPI/appParams'
import { createControllersParams } from './appsAPI/controllerParams'
import { createPlatformAppServicesApi } from './appsAPI/platformServicesAPI'
import { importAndInitElementorySupport } from './elementorySupport'
import { ClientSpecMapAPI } from './clientSpecMapService'
import { AppsUrlAPI } from './appsUrlService'
import { WixCodeViewerAppUtils } from './wixCodeViewerAppUtils'
import { ModuleLoader } from './loadModules'
import { WixSelector } from './wixSelector'
import { BsiManager } from './bsiManagerModule'
import { CreateSetPropsForOOI } from './setPropsFactory'
import { WixCodeAppDefId } from './constants'
import { WixCodeApiFactory } from './createWixCodeSdk'
import { AppsPublicApiManager } from './appsPublicApiManager'
import { initializeDebugApi } from './debug'

export function Applications({
	appsPublicApiManager,
	wixSelector,
	modelsApi,
	clientSpecMapApi,
	appsUrlApi,
	bootstrapData,
	importScripts,
	moduleLoader,
	wixCodeViewerAppUtils,
	logger,
	wixCodeApiFactory,
	createSetPropsForOOI,
	waitForUpdatePropsPromises,
	controllersExports,
	createPlatformApiForApp,
	bsiManager,
	platformUtils,
}: {
	appsPublicApiManager: AppsPublicApiManager
	wixSelector: WixSelector
	modelsApi: ModelsAPI
	clientSpecMapApi: ClientSpecMapAPI
	appsUrlApi: AppsUrlAPI
	bootstrapData: BootstrapData
	importScripts: Function
	moduleLoader: ModuleLoader
	wixCodeViewerAppUtils: WixCodeViewerAppUtils
	logger: PlatformLogger
	wixCodeApiFactory: WixCodeApiFactory
	createSetPropsForOOI: CreateSetPropsForOOI
	waitForUpdatePropsPromises: () => Promise<any>
	controllersExports: ControllersExports
	createPlatformApiForApp: (applicationId: string, instanceId: string) => PlatformAPI
	bsiManager: BsiManager
	platformUtils: PlatformUtils
}) {
	const {
		wixCodeBootstrapData,
		platformEnvData: {
			bi: biData,
			document: documentData,
			router: { dynamicRouteData },
			window: { csrfToken },
			site: { experiments, mode },
		},
	} = bootstrapData

	const applications = modelsApi.getApplications()
	const connections = modelsApi.getAllConnections()
	const isAppRunning = (appDefId: string | undefined) => appDefId && applications[appDefId]
	const isWixCodeRunning = !!isAppRunning(clientSpecMapApi.getWixCodeAppDefinitionId())
	const isDatabindingRunning = !!isAppRunning(clientSpecMapApi.getDataBindingAppDefinitionId())
	const isAppStudioRunning = _.some(clientSpecMapApi.getStudioAppsAppDefinitionIds(), (app) => isAppRunning(app))

	async function loadControllerModule({ controllerType, applicationId: appDefinitionId }: ControllerDataItem): Promise<Record<string, any>> {
		const controllerScriptUrl = appsUrlApi.getControllerScriptUrl(appDefinitionId, controllerType)
		const controllerModule = controllerScriptUrl ? await moduleLoader.AMDLoader(controllerScriptUrl, 'controllerScript', { appDefinitionId, controllerType }) : null
		return controllerModule ? { [controllerType]: controllerModule } : {}
	}

	async function startApplications() {
		if (isWixCodeRunning || isDatabindingRunning || isAppStudioRunning) {
			await importAndInitElementorySupport({
				importScripts,
				wixCodeBootstrapData,
				sessionService: platformUtils.sessionService,
				viewMode: biData.isPreview ? 'preview' : 'site',
				csrfToken,
				logger,
			})
		}

		const runApplication = async (appDefinitionId: string) => {
			const controllers = applications[appDefinitionId]
			const controllersData = _.values(controllers)
			const viewerScriptUrl = appsUrlApi.getViewerScriptUrl(appDefinitionId)
			if (!viewerScriptUrl) {
				/**
				 * Might be because clientSpecMap data corruption (App is missing) or might be because OOI migration
				 */
				const error = new Error('Could not find viewerScriptUrl. The Application might be missing from the CSM')
				logger.captureError(error, {
					tags: { missingViewerScriptUrl: true },
					extra: { appDefinitionId },
				})
				appsPublicApiManager.resolvePublicApi(appDefinitionId, null)
				return
			}
			const appSpecData = clientSpecMapApi.getAppSpecData(appDefinitionId)
			// TODO PLAT-1018 those are fake promises. AMDLoader importScripts modules.
			const appModulePromise = moduleLoader.AMDLoader<AppModule>(viewerScriptUrl, 'viewerScript', { appDefinitionId })
			const controllerModulesPromise = Promise.all(_.map(controllersData, loadControllerModule)).then((modulesArray) => Object.assign({}, ...modulesArray))
			const routerConfigMap = _.filter(bootstrapData.platformAPIData.routersConfigMap, { appDefinitionId })
			const appParams = createAppParams({
				appSpecData,
				wixCodeViewerAppUtils,
				dynamicRouteData,
				routerConfigMap,
				appInstance: platformUtils.sessionService.getInstance(appDefinitionId),
				baseUrls: appsUrlApi.getBaseUrls(appDefinitionId),
				viewerScriptUrl,
			})
			const instanceId = appParams.instanceId
			const platformApi = createPlatformApiForApp(appDefinitionId, instanceId)
			const platformAppServicesApi = createPlatformAppServicesApi({
				documentData,
				biData,
				appDefinitionId,
				instanceId,
				experiments,
				csrfToken,
				bsiManager,
				sessionService: platformUtils.sessionService,
			})

			const wixCodeApi = await wixCodeApiFactory.initWixCodeApiForApplication(appDefinitionId)
			/*
			 * TODO storage is a namespace in the sense that you can "import storage from wix-storage",
			 *  but it's not a namespace in the sense that it's bound to appDefId and instanceId.
			 *  consider creating wixCodeApi per app.
			 */
			if (appDefinitionId === WixCodeAppDefId) {
				if (mode.debug) {
					initializeDebugApi({ wixCodeApi })
				}
				wixCodeApi.storage = platformApi.storage
			}
			const widgetNames = clientSpecMapApi.getAppWidgetNames(appDefinitionId)
			platformUtils.wixCodeNamespacesRegistry.registerWixCodeNamespaces(wixCodeApi)
			const controllersParams = createControllersParams(
				createSetPropsForOOI,
				controllersData,
				connections,
				wixSelector,
				widgetNames,
				appParams,
				wixCodeApi,
				platformAppServicesApi,
				platformApi,
				csrfToken
			)

			const appModule = await appModulePromise
			if (!appModule) {
				// error loading app module. errors are reported via moduleLoader.
				appsPublicApiManager.resolvePublicApi(appDefinitionId, null)
				return
			}

			if (appModule.initAppForPage) {
				await logger.withReportingAndErrorHandling('init_app_for_page', () => appModule.initAppForPage!(appParams, platformApi, wixCodeApi, platformAppServicesApi), { appDefinitionId })
			}
			const controllerModules = await controllerModulesPromise

			logger.reportAppPhasesNetworkAnalysis(appDefinitionId)
			const controllerPromises = await logger.withReportingAndErrorHandling(
				'create_controllers',
				() =>
					appModule.createControllers(
						controllersParams.map((item) => item.controllerParams),
						controllerModules
					),
				{ appDefinitionId }
			)

			const controllersApi: ControllersApi = { getAll: () => controllerPromises || [] }
			const exports = _.isFunction(appModule.exports) ? appModule.exports({ controllersApi }) : appModule.exports

			appsPublicApiManager.resolvePublicApi(appDefinitionId, exports)

			if (!controllerPromises) {
				return
			}

			await Promise.all(
				controllerPromises.map(async (controllerPromise, index) => {
					const { controllerCompId, controllerParams } = controllersParams[index]
					const reportingParams = { appDefinitionId, controllerType: controllerParams.type }
					const controller = await logger.withReportingAndErrorHandling('await_controller_promise', () => controllerPromise, reportingParams)
					if (!controller) {
						return
					}

					controllersExports[controllerCompId] = controller.exports
					const pageReadyFunc = () => Promise.resolve(controller.pageReady(controllerParams.$w, wixCodeApi))
					wixSelector.onPageReady(() => logger.withReportingAndErrorHandling('controller_page_ready', pageReadyFunc, reportingParams), controllerCompId)
				})
			)
		}

		appsPublicApiManager.registerPublicApiProvider(runApplication)

		await Promise.all(
			_.map(applications, (__, appDefinitionId) =>
				runApplication(appDefinitionId).catch((error) => {
					appsPublicApiManager.resolvePublicApi(appDefinitionId, null)
					logger.captureError(error, { tags: { method: 'runApplication' }, extra: { appDefinitionId } })
				})
			)
		)
		await wixSelector.flushOnReadyCallbacks()
		await waitForUpdatePropsPromises()
	}

	return {
		startApplications,
	}
}
