import { BiUtils, ConsentPolicyManager, LinkUtils, LocationManager, PlatformUtils, SessionServiceAPI, WarmupDataManager, WixCodeNamespacesRegistry } from '@wix/thunderbolt-symbols'
import { AppsPublicApiManager } from './appsPublicApiManager'

export function BuildPlatformUtils({
	linkUtils,
	sessionService,
	appsPublicApiManager,
	wixCodeNamespacesRegistry,
	biUtils,
	locationManager,
	warmupDataManager,
	consentPolicyManager,
}: {
	linkUtils: LinkUtils
	sessionService: SessionServiceAPI
	appsPublicApiManager: AppsPublicApiManager
	wixCodeNamespacesRegistry: WixCodeNamespacesRegistry
	biUtils: BiUtils
	locationManager: LocationManager
	warmupDataManager: WarmupDataManager
	consentPolicyManager: ConsentPolicyManager
}): PlatformUtils {
	return {
		linkUtils,
		sessionService,
		appsPublicApisUtils: {
			getPublicAPI: appsPublicApiManager.getPublicApi,
		},
		wixCodeNamespacesRegistry,
		biUtils,
		locationManager,
		warmupDataManager,
		consentPolicyManager,
	}
}
