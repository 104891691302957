import { BootstrapData, InitPlatformOnSiteArgs, ViewerAPI } from '../types'
import { initWorkerOnSite, runWorkerOnPage } from '../core/worker'
import { clearTimeouts } from '../client/timeoutsManager'
import _ from 'lodash'
import { Logger } from '@wix/web-bi-logger/dist/src/types' // eslint-disable-line no-restricted-syntax
import { manager as biLoggersManager } from '@wix/web-bi-logger/dist/src/logger' // eslint-disable-line no-restricted-syntax
import { proxy } from 'comlink/dist/esm/comlink.js' // eslint-disable-line no-restricted-syntax
import { GetModels, PlatformWorkerCommonApi } from '../core/types'
import { PlatformLogger } from '@wix/thunderbolt-symbols'

export function createCommonWorker(): PlatformWorkerCommonApi {
	const webBiLoggers: Array<Logger> = []
	// @ts-ignore
	biLoggersManager.onLoggerCreated((logger: Logger) => webBiLoggers.push(logger))

	function initPlatformOnSite({ platformEnvData, appsUrlData, componentSdksClientUrl }: InitPlatformOnSiteArgs) {
		initWorkerOnSite({
			platformEnvData,
			appsUrlData,
			componentSdksUrl: componentSdksClientUrl,
		})
	}

	async function runPlatformOnPage({
		bootstrapData,
		updateProps,
		updateStyles,
		invokeSdkHandler,
		modelsProviderFactory,
	}: {
		bootstrapData: BootstrapData
		updateProps: ViewerAPI['updateProps']
		updateStyles: ViewerAPI['updateStyles']
		invokeSdkHandler: ViewerAPI['invokeSdkHandler']
		modelsProviderFactory: (logger: PlatformLogger) => GetModels
	}) {
		// Clear timeouts on navigation, after leaving the first page.
		// This should be done per page (Also when lightbox closes) Will be done on https://jira.wixpress.com/browse/PLAT-1219
		const { isLightbox, pageNumber } = bootstrapData.platformEnvData.bi.pageData
		if (pageNumber > 1 && !isLightbox) {
			// The bi loggers are flushing themselves with timeouts. So we need to explicitly flush and await them to
			// avoid destroying their batching with clearTimeouts().
			await Promise.all(webBiLoggers.map((logger) => logger.flush()))
			webBiLoggers.length = 0
			clearTimeouts()
		}

		const arrayOfUpdatePromises: Array<Promise<any> | void> = []
		const viewerAPI: ViewerAPI = {
			updateProps: (data: any) => {
				const promise = updateProps(data)
				arrayOfUpdatePromises.push(promise)
			},
			updateStyles: (data: any) => {
				const promise = updateStyles(data)
				arrayOfUpdatePromises.push(promise)
			},
			invokeSdkHandler: (pageId, path, ...args) => {
				if (args.length > 4) {
					console.error('sdk handlers support up to 4 arguments')
					return
				}
				const proxiedArgs = args.map((arg: any) => (_.isFunction(arg) ? proxy(arg) : arg))
				const promise = invokeSdkHandler(pageId, path, proxiedArgs[0], proxiedArgs[1], proxiedArgs[2], proxiedArgs[3])
				if (path === 'setControllerProps') {
					arrayOfUpdatePromises.push(promise)
				}
				return promise
			},
		}

		await runWorkerOnPage({
			viewerAPI,
			bootstrapData,
			modelsProviderFactory,
		})
		// wait for all prop updates to finish before resolving the main platform promise to make sure props are updated before render
		await Promise.all(arrayOfUpdatePromises)
	}
	return {
		initPlatformOnSite,
		runPlatformOnPage,
	}
}
