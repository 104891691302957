import { createFedopsLogger } from '@wix/thunderbolt-commons'
import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace, WixStoresWixCodeSdkWixCodeApi } from '..'
import { WixStoresSdk } from './WixStoresSdk'
import { CartServiceSdk } from './services/cart'

export function WixStoresSdkFactory({
	platformEnvData,
	platformUtils,
}: WixCodeApiFactoryArgs): { [namespace]: WixStoresWixCodeSdkWixCodeApi } {
	const { sessionService, biUtils, appsPublicApisUtils } = platformUtils
	const { bi: biData } = platformEnvData

	const biLoggerFactory = biUtils.createBiLoggerFactoryForFedops(biData)
	const fedopsLogger = createFedopsLogger({
		biLoggerFactory,
		phasesConfig: 'SEND_START_AND_FINISH',
		appName: 'wixstores-wix-code-sdk',
	})

	const wixStoresSdk = new WixStoresSdk(sessionService, fedopsLogger, appsPublicApisUtils)
	const cartServiceSdk = new CartServiceSdk(sessionService, fedopsLogger, appsPublicApisUtils)

	return {
		[namespace]: {
			async getProductOptionsAvailability(
				productId: string,
				options: { [key: string]: string } = {}
			): Promise<any> {
				return wixStoresSdk.getProductOptionsAvailability(productId, options)
			},
			async getProductVariants(productId: string, options: { [key: string]: string } = {}): Promise<any> {
				return wixStoresSdk.getProductVariants(productId, options)
			},
			async getCurrentCart(): Promise<any> {
				return wixStoresSdk.getCurrentCart()
			},
			onCartChanged(handler: (cart: any) => void) {
				wixStoresSdk.onCartChanged(handler)
			},
			removeProductFromCart(cartItemId: number): Promise<any> {
				return wixStoresSdk.removeProductFromCart(cartItemId)
			},
			addCustomItemsToCart(customItems: Array<any>): Promise<any> {
				return wixStoresSdk.addCustomItemsToCart(customItems)
			},
			cart: {
				applyCoupon(couponCode: string): Promise<any> {
					return cartServiceSdk.applyCoupon(couponCode)
				},
				removeCoupon(): Promise<any> {
					return cartServiceSdk.removeCoupon()
				},
				updateLineItemQuantity(cartItemId: number, quantity: number): Promise<any> {
					return cartServiceSdk.updateLineItemQuantity(cartItemId, quantity)
				},
				addProducts(products: Array<any>): Promise<any> {
					return cartServiceSdk.addProducts(products)
				},
				showMiniCart(): void {
					cartServiceSdk.showMiniCart()
				},
				hideMiniCart(): void {
					cartServiceSdk.hideMiniCart()
				},
			},
		},
	}
}
