import 'proxy-polyfill'
import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { expose } from 'comlink/dist/esm/comlink.js' // eslint-disable-line no-restricted-syntax
import { PlatformClientWorkerAPI } from '../core/types'
import { createCommonWorker } from '../worker-commons/clientWorker'
import { BootstrapData, ViewerAPI } from '../types'
import { getModelsFactory } from '../core/getRawModels'
import { siteAssetsClientWorkerAdapter } from './initSiteAssetsClient'

const { initPlatformOnSite, runPlatformOnPage: runPlatformOnPageCommonWorker } = createCommonWorker()

function runPlatformOnPage(bootstrapData: BootstrapData, updateProps: ViewerAPI['updateProps'], invokeSdkHandler: ViewerAPI['invokeSdkHandler'], updateStyles: ViewerAPI['updateStyles']) {
	function modelsProviderFactory(logger: PlatformLogger) {
		const siteAssetsClient = siteAssetsClientWorkerAdapter(bootstrapData.platformEnvData, logger)
		const { getModels } = getModelsFactory({ bootstrapData, logger, siteAssetsClient })
		return getModels
	}

	return runPlatformOnPageCommonWorker({
		bootstrapData,
		updateProps,
		updateStyles,
		invokeSdkHandler,
		modelsProviderFactory,
	})
}

const platformClientWorkerAPI: PlatformClientWorkerAPI = {
	initPlatformOnSite,
	runPlatformOnPage,
}
expose(platformClientWorkerAPI)
