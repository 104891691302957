import _ from 'lodash'

import { ViewerAppSpecData, WidgetNames, AppDefinitionId } from '@wix/thunderbolt-symbols'
import { BootstrapData } from '../types'

export const DATA_BINDING_APP_DEF_ID = 'dataBinding'

export type ClientSpecMapAPI = {
	getViewerScriptUrl(appDefinitionId: AppDefinitionId): string | null
	getControllerScript(appDefinitionId: AppDefinitionId, widgetId: string): string
	getAppSpecData(appDefinitionId: AppDefinitionId): ViewerAppSpecData
	getAppWidgetNames(appDefinitionId: AppDefinitionId): WidgetNames
	isWixCodeInstalled(): boolean
	getWixCodeAppDefinitionId(): AppDefinitionId | undefined
	getDataBindingAppDefinitionId(): AppDefinitionId
	getStudioAppsAppDefinitionIds(): Array<AppDefinitionId>
	isAppOnSite(appDefinitionId: AppDefinitionId): boolean
	getAppsOnSite(): Array<AppDefinitionId>
}

export default function ({ bootstrapData }: { bootstrapData: BootstrapData }): ClientSpecMapAPI {
	const { appsSpecData, wixCodeBootstrapData, appsUrlData, studioAppsAppDefinitionIds, widgetNames } = bootstrapData
	const wixCodeAppData = wixCodeBootstrapData.wixCodeAppDefinitionId && appsSpecData[wixCodeBootstrapData.wixCodeAppDefinitionId]

	return {
		getViewerScriptUrl(appDefinitionId) {
			return _.get(appsUrlData, [appDefinitionId, 'viewerScriptUrl'])
		},
		getControllerScript(appDefinitionId, widgetId) {
			return _.get(appsUrlData, [appDefinitionId, 'widgets', widgetId, 'controllerUrl'])
		},
		getAppSpecData(appDefinitionId) {
			return appsSpecData[appDefinitionId]
		},
		getAppWidgetNames(appDefinitionId) {
			return _.get(widgetNames, [appDefinitionId, 'widgets'], {})
		},
		isWixCodeInstalled() {
			return !!wixCodeAppData
		},
		getWixCodeAppDefinitionId() {
			return wixCodeBootstrapData.wixCodeAppDefinitionId
		},
		getDataBindingAppDefinitionId() {
			return DATA_BINDING_APP_DEF_ID
		},
		getStudioAppsAppDefinitionIds() {
			return studioAppsAppDefinitionIds
		},
		isAppOnSite(appDefinitionId) {
			return !!appsSpecData[appDefinitionId]
		},
		getAppsOnSite() {
			return _.keys(appsSpecData)
		},
	}
}
